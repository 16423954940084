import md5 from "md5";
import { useState } from "react";

export function Auth({ setIsAuth }) {
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");

  async function handleLogin() {
    const _username = md5(username);
    const _pass = md5(pass);

    const passHash = process.env.REACT_APP_PASSWORD;
    const userHash = process.env.REACT_APP_USERNAME;

    setIsAuth(passHash === _pass && userHash === _username);
  }

  return (
    <div>
      <div className="column-title">
        <h1>Holy login, Batman!</h1>
      </div>

      <div className="column-title">
        <input
          type="text"
          value={username}
          placeholder="Enter username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <div className="column-title">
        <input
          type="password"
          value={pass}
          placeholder="Enter password"
          onChange={(e) => setPass(e.target.value)}
        />
      </div>

      <button onClick={handleLogin} className="button">
        Log in
      </button>
    </div>
  );
}
