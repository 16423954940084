import axios from "axios";
import { useEffect, useState } from "react";
import { Typewriter } from "../Typewriter";

export default function Dash() {
  const [queue, setQueue] = useState([]);
  const [queueTime, setQueueTime] = useState();
  const [serverList, setServerList] = useState([]);
  const [flaggedPrompts, setFlaggedPrompts] = useState([]);
  const [runningTasks, setRunningTasks] = useState([]);
  const [robinStatus, setRobinStatus] = useState(false);
  const [autoScalingAllowed, setAutoScalingAllowed] = useState(false);
  const [maxServers, setMaxServers] = useState(1);
  const [serverQuota, setServerQuota] = useState(1);
  const [avgTaskTime, setAvgTaskTime] = useState();

  const apiKey = process.env.REACT_APP_API_KEY;

  async function toggleAutoScaler() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/toggleautoscaler`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  // Fetches a list of all active servers in the server list
  async function fetchServerList() {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/runningservers`,
      {
        headers: {
          "X-API-KEY": apiKey,
        },
      },
    );

    // console.log(res.data);
    setServerList(res.data);
  }

  async function fetchConfig() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/getconfig`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);

      // console.log(res.data);
      setAutoScalingAllowed(res.data.autoScalingAllowed);
      setServerQuota(res.data.serverQuota);
      setMaxServers(res.data.maxServers);
    } catch (error) {
      console.error(error);
    }
  }

  // Fetches the current queued tasks and displays the task id
  async function fetchQueue() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/queuedtasks`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      // console.log(res.data);
      setQueue(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  // Fetches a list of all current running tasks and displays their progress && task id
  async function fetchRunningTasks() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/runningtasks`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      // console.log(res.data);
      setRunningTasks(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  // This tells us if Robin is running or not
  async function fetchRobinStatus() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/ping`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      // console.log(res.data);

      if (res.status === 200) {
        setRobinStatus(true);
      } else {
        setRobinStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
    // setQueue(res.data);
  }

  // This gets the estimated queue time
  async function fetchQueueTime() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/queuetime`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      // console.log(res.data);

      setQueueTime(res.data);
    } catch (error) {
      console.error(error);
    }
    // setQueue(res.data);
  }

  // This gets the estimated queue time
  async function fetchAvgTaskTime() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/avgtasktime`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      // console.log(res.data);

      setAvgTaskTime(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchFlaggedPrompts() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/flaggedprompts`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        },
      );

      setFlaggedPrompts(res.data);
    } catch (error) {
      console.error(error);
    }
    // setQueue(res.data);
  }

  async function banUser(userId, prompt) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/banuser`,
        { userId, prompt },
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
    } catch (error) {
      // console.error(error);
    }
  }

  async function clearPrompt(promptId) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/clearprompt`,
        { promptId },
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
    } catch (error) {
      // console.error(error);
    }
  }

  // Runs on page load and every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      fetchServerList();
      fetchQueue();
      fetchRobinStatus();
      fetchRunningTasks();
      fetchQueueTime();
      fetchAvgTaskTime();
      fetchFlaggedPrompts();
      fetchConfig();
    }, 2000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-column">
      <div className="app-column-row">
        <div className="column">
          <div className="column-title">
            <h1>Robin</h1>
          </div>

          <div className="column-title">
            <span>Status: {robinStatus ? "🟢" : "🔴"}</span>
          </div>

          <div className="column-title">
            <AddServerTile />
          </div>

          <div className="column-content">
            {runningTasks &&
              runningTasks.map((task) => {
                return <RunningTaskTile key={task.id} task={task} />;
              })}
          </div>
        </div>

        <div className="column">
          <div className="column-title">
            <h1>Servers - {serverList?.length}</h1>
          </div>

          <div className="column-content">
            {serverList &&
              serverList.map((server) => {
                return <ServerTile key={server.id} server={server} />;
              })}
          </div>
        </div>

        <div className="column">
          <div className="column-title">
            <h1>Queued tasks - {queue?.length}</h1>
          </div>

          <div className="column-title">
            <span>Estimated queue time: {queueTime} sec</span>
          </div>

          <div className="column-title">
            <span>Average task time: {avgTaskTime} sec</span>
          </div>

          <div className="column-title">
            <span>
              Auto scaling allowed: {autoScalingAllowed ? "Yes" : "No"}
            </span>
          </div>

          <div className="column-title">
            <div style={{ cursor: "pointer" }} onClick={toggleAutoScaler}>
              Toggle auto scaling: {autoScalingAllowed ? "🟢" : "🔴"}
            </div>{" "}
          </div>

          <div className="column-title">
            <span>Max Servers: {maxServers}</span>
          </div>

          <div className="column-title">
            <span>Server Quota: {serverQuota}</span>
          </div>

          <div className="column-content">
            {queue &&
              queue.map((task) => {
                return <QueueTile key={task.id} task={task} />;
              })}
          </div>
        </div>
      </div>

      <div className="bottom-column">
        {flaggedPrompts &&
          flaggedPrompts.map((flaggedPrompt) => {
            const { prompt, userId, promptId } = flaggedPrompt;

            return (
              <div className="flagged-prompt">
                <div className="flagged-prompt-actions">
                  <button
                    className="button"
                    onClick={() => {
                      banUser(userId, prompt);
                      clearPrompt(promptId);
                    }}
                    type="button"
                  >
                    Ban
                  </button>

                  <button
                    className="button"
                    onClick={() => clearPrompt(promptId)}
                    type="button"
                  >
                    Clear
                  </button>
                </div>

                <div className="flagged-prompt-content">
                  <p>
                    [{userId}]: {prompt}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function ServerTile({ server }) {
  const [isConfirm, setIsConfirm] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;

  // Removes an Alfred instance via url
  async function removeServer() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/removeserver`,
        { url: server.url },
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
    } catch (error) {
      // console.error(error);
    }
  }

  function toggleConfirm() {
    setIsConfirm(true);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isConfirm) {
        setIsConfirm(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isConfirm]);

  return (
    <div className="server-tile">
      <p className="server-tile-status">
        <Typewriter text="Status: " delay={25} />
        {server.status === "ready" ? "🟢" : "🟡"}
      </p>

      <p>
        ID:{" "}
        <span className="server-tile-id">
          <Typewriter text={server.id} delay={25} />
        </span>
      </p>

      <p>
        <Typewriter text="Url: " delay={25} />
        <a href={server.url} target="_blank" rel="noreferrer">
          <span className="server-tile-url">
            <Typewriter text={server.url} delay={25} />
          </span>
        </a>
      </p>

      <p>
        vmName:{" "}
        <span className="server-tile-id">
          <Typewriter text={server.vmName} delay={25} />
        </span>
      </p>

      <p>
        vmType:{" "}
        <span className="server-tile-id">
          <Typewriter text={server.vmType} delay={25} />
        </span>
      </p>

      {isConfirm ? (
        <div onClick={removeServer} className="server-tile-delete">
          ARE YOU SURE?
        </div>
      ) : (
        <div onClick={toggleConfirm} className="server-tile-delete">
          Delete
        </div>
      )}
    </div>
  );
}

function QueueTile({ task }) {
  const apiKey = process.env.REACT_APP_API_KEY;

  async function removeTask() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/removetask`,
        { taskId: task.id },
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="server-tile">
      <p>
        <span onClick={removeTask} className="queue-tile-delete">
          ❌
        </span>

        <Typewriter text="ID: " delay={60} />

        <span className="server-tile-id">
          <Typewriter text={task.id} delay={60} />
        </span>
      </p>
    </div>
  );
}

function RunningTaskTile({ task }) {
  const [isConfirm, setIsConfirm] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;

  async function removeTask() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/removetask`,
        { taskId: task.id },
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  function toggleConfirm() {
    setIsConfirm(true);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isConfirm) {
        setIsConfirm(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isConfirm]);

  return (
    <div className="server-tile">
      <p>
        <Typewriter text="ID: " delay={50} />

        <span className="server-tile-id">
          <Typewriter text={task.id} delay={50} />
        </span>
      </p>

      <p>
        Progress:
        <div
          className="server-tile-id"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <div
            style={{
              left: 0,
              position: "absolute",
              height: "100%",
              width: Math.floor(task.progress * 100) + "%" || "0%",
              background: "linear-gradient(to right,  limegreen, lime)",
            }}
          />
          <span>{Math.floor(task.progress * 100)}%</span>
        </div>
      </p>

      {isConfirm ? (
        <div onClick={removeTask} className="server-tile-delete">
          ARE YOU SURE?
        </div>
      ) : (
        <div onClick={toggleConfirm} className="server-tile-delete">
          Delete
        </div>
      )}
    </div>
  );
}

function AddServerTile({ setUrl }) {
  const [isConfirm, setIsConfirm] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;

  async function addServer() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/spinUpServer`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        },
      );

      console.log(res.data);
      setUrl("");
    } catch (error) {
      // console.error(error);
    }
  }
  function toggleConfirm() {
    setIsConfirm(true);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isConfirm) {
        setIsConfirm(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isConfirm]);

  return isConfirm ? (
    <div onClick={addServer} className="server-tile-add">
      ARE YOU SURE?
    </div>
  ) : (
    <div onClick={toggleConfirm} className="server-tile-add">
      SPIN UP SERVER
    </div>
  );
}
