import { useState } from "react";
import { Auth } from "./components/Auth";
import Dash from "./components/Dash";
import { Typewriter } from "./components/Typewriter";

export default function App() {
  const [isAuth, setIsAuth] = useState(false);

  return (
    <>
      <div className="header">
        <h1>
          <Typewriter text="ArtForge Labs" delay={200} />
        </h1>
      </div>

      <div className="app-container">
        {isAuth ? <Dash /> : <Auth setIsAuth={setIsAuth} />}
      </div>
    </>
  );
}
